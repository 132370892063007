<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st0" d="M100,158.3c-26.7,0-53.3,0-80,0c-12.5,0-19.4-7-19.5-19.5c0-6.7,0-13.5,0-20.2c0-4.7,2.2-6.8,6.9-6.9   c1.9,0,3.9,0.1,5.8-0.1c6.1-0.6,10.7-5.8,10.6-11.9c-0.1-5.9-4.8-10.8-10.7-11.3c-2.1-0.2-4.1,0-6.2-0.1c-4-0.2-6.3-2.3-6.3-6.3   c-0.1-7.9-0.2-15.8,0-23.7c0.3-9.5,8-16.6,18-16.6c23.9-0.1,47.9,0,71.8,0c29.8,0,59.5,0,89.3,0c12.7,0,19.6,6.9,19.7,19.7   c0,6.6,0,13.2,0,19.8c0,5-2.1,7-7.1,7.1c-1.7,0-3.4-0.1-5,0c-6.3,0.4-11.2,5.5-11.1,11.7c0,6.2,4.9,11.2,11.3,11.5   c1.9,0.1,3.9-0.1,5.8,0c3.8,0.2,6.1,2.3,6.1,6.1c0.1,8,0.2,16.1,0,24.1c-0.3,9.4-8.1,16.4-18.2,16.4   C154.1,158.3,127.1,158.3,100,158.3C100,158.3,100,158.3,100,158.3z M77.9,134.9c1-0.8,3.2-2,4.6-3.8   c14.8-18.3,29.4-36.7,44.1-55.1c0.6-0.8,1.4-1.6,1.8-2.5c1.2-2.8,0.8-5.4-1.7-7.3c-2.4-1.8-4.9-1.6-7.1,0.3   c-0.9,0.7-1.6,1.7-2.3,2.6c-14.5,18.2-29.1,36.3-43.6,54.5c-0.7,0.9-1.5,1.8-2,2.8C69.7,130.4,72.4,134.8,77.9,134.9z M76.6,65.1   c-9.6,0-17.4,7.9-17.4,17.6c0.1,9.5,7.8,17.2,17.3,17.3c9.7,0.1,17.5-7.7,17.6-17.4C94.1,72.9,86.3,65,76.6,65.1z M140.7,117.4   c0-9.7-7.9-17.5-17.6-17.4c-9.5,0.1-17.2,7.8-17.3,17.3c-0.1,9.7,7.7,17.6,17.4,17.6C132.9,135,140.7,127.1,140.7,117.4z"/>
            <path class="st0" d="M82.5,82.7c-0.1,3.2-3,5.9-6.2,5.6c-3-0.2-5.5-2.9-5.4-6c0.1-3.1,2.7-5.6,5.8-5.6   C79.9,76.7,82.6,79.5,82.5,82.7z"/>
            <path class="st0" d="M123.3,123.3c-3.2,0-5.9-2.8-5.8-6c0.1-3.1,2.7-5.6,5.8-5.6c3.2,0,5.9,2.8,5.8,6   C129,120.8,126.4,123.3,123.3,123.3z"/>
        </g>
    </icon-base>
</template>
<script>
export default {
    name: 'iconGiftCode',       
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--dark-main-navy)'
        },
        enableBackground: {
        type: Boolean
        }
    }
}
</script>